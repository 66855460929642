import {
    EthereumClient,
    w3mConnectors,
    w3mProvider,
} from "@web3modal/ethereum";
import { Web3Modal } from "@web3modal/react";
import { useEffect, useState } from "react";
import { configureChains, createConfig, WagmiConfig } from "wagmi";
import { mainnet, optimism, polygon } from "wagmi/chains";
import React from "react";
import styled from "styled-components";
import HomePage from "./pages/Home";

const Body = styled.div`
    position: relative;
`;

const ModalOverlay = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
`;

const ModalContent = styled.div`
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    max-width: 600px;
    width: 90%;
    position: relative;
`;

const CloseButton = styled.button`
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 20px;
    cursor: pointer;
`;

const projectId = "72144217ccaab12d01cbdde6f9406274";

const chains = [mainnet, polygon, optimism];

const { publicClient } = configureChains(chains, [w3mProvider({ projectId })]);
const wagmiConfig = createConfig({
    autoConnect: true,
    connectors: w3mConnectors({ chains, projectId }),
    publicClient,
});

// 3. Configure modal ethereum client
const ethereumClient = new EthereumClient(wagmiConfig, chains);

const App = () => {
    const [ready, setReady] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(true);

    const closeModal = () => {
        setIsModalOpen(false);
    };

    useEffect(() => {
        setReady(true);
    }, []);

    return (
        <>
            {ready ? (
                <WagmiConfig config={wagmiConfig}>
                    <Body>
                        {/* {isModalOpen && (
                            <ModalOverlay>
                                <ModalContent>
                                    <CloseButton onClick={closeModal}>
                                        &times;
                                    </CloseButton>
                                    <h2>
                                        Why Verification is Necessary Before
                                        Purchasing SALL Token or SALL ICO.
                                    </h2>
                                    <p>
                                        At SALLPresale.com, we prioritize the
                                        security and compliance of our investors
                                        above all else. The verification step
                                        before purchasing the SALL token or
                                        participating in the SALL ICO is an
                                        essential part of this commitment.
                                        Here's why this step is crucial:
                                    </p>
                                    <ul>
                                        <li>
                                            <strong>Regulatory Compliance:</strong>{" "}
                                            We ensure that all investors are
                                            familiar with and agree to the
                                            regulations and terms outlined on
                                            our website. This adherence to
                                            regulatory requirements helps
                                            maintain a transparent and lawful
                                            investment environment.
                                        </li>
                                        <li>
                                            <strong>Investor Protection:</strong>{" "}
                                            By verifying the identity of our
                                            investors, we add an additional
                                            layer of security. This step helps
                                            protect our investors from potential
                                            fraud and scams, providing peace of
                                            mind when investing in the SALL
                                            token.
                                        </li>
                                        <li>
                                            <strong>Anti-Money Laundering (AML)
                                                and Know Your Customer (KYC)
                                                Requirements:</strong> Compliance
                                            with AML and KYC regulations is a
                                            standard practice in the financial
                                            and cryptocurrency sectors. These
                                            regulations help prevent illicit
                                            activities and ensure that the funds
                                            used in our token sales come from
                                            legitimate sources.
                                        </li>
                                        <li>
                                            <strong>Market Integrity:</strong>{" "}
                                            Verification helps maintain the
                                            integrity of the market by ensuring
                                            that all participants are genuine
                                            and informed about the investment.
                                            This contributes to a fair and
                                            orderly market environment.
                                        </li>
                                    </ul>
                                    <p>
                                        It's important to note that our
                                        verification process does never request
                                        bank information codes CVVs. The sole
                                        purpose of verification is to confirm
                                        that investors have read and understood
                                        the regulations and terms and conditions
                                        specified on our website.
                                    </p>
                                    <p>
                                        By completing the verification process,
                                        investors can be assured that they are
                                        entering a secure and regulated
                                        investment platform. This step is a
                                        normal and necessary measure to protect
                                        both the investors and the integrity of
                                        the SALL token sale.
                                    </p>
                                    <p>
                                        For more details, please refer to the
                                        Terms and Conditions section on our
                                        website.
                                    </p>
                                    <p>
                                        System Of All, SALL Token
                                    </p>
                                </ModalContent>
                            </ModalOverlay>
                        )} */}
                        <HomePage />
                    </Body>
                </WagmiConfig>
            ) : null}
            <Web3Modal projectId={projectId} ethereumClient={ethereumClient} />
        </>
    );
};

export default App;
